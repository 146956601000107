import Cookies from 'universal-cookie';
import { Option } from '@/components/ui';
import { HOMEPAY_PARTNERS_RX_COOKIE, StateCodeMap } from '@/constants';

export const getStatesListOptions = (): Option[] =>
  Object.keys(StateCodeMap).map((key) => ({
    label: key,
    value: StateCodeMap[key as keyof typeof StateCodeMap],
  }));

export const getPartnersRXCookie = (): string => {
  const cookies = new Cookies();
  return cookies.get(HOMEPAY_PARTNERS_RX_COOKIE) || '';
};

export const scrollForLazyLoad = async (page: any) => {
  await page.evaluate(async () => {
    const delay = (ms: any) =>
      new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    for (let i = 0; i < document.body.scrollHeight; i += 100) {
      window.scrollTo(0, i);
      // eslint-disable-next-line no-await-in-loop
      await delay(100);
    }

    window.scrollTo(0, 0);
  });
};
